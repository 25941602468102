import React, { useState, useEffect } from "react";
import client4 from "../Utils/Assets/slide/Ellipse 57 (3).png";
import client5 from "../Utils/Assets/slide/Ellipse 57 (4).png";
import client6 from "../Utils/Assets/slide/Ellipse 57 (5).png";
import client7 from "../Utils/Assets/slide/Ellipse 57 (6).png";
import next from "../Utils/Assets/slide/Group 39476.png";

const BottomReview = () => {
  const myteam = [
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "John Doe",
      designation: "Software Tester",
      img: client4,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Clark Kent",
      designation: "Ethical Hacker",
      img: client5,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "John Doe",
      designation: "Scrum Master",
      img: client6,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Banner",
      designation: "Software Tester",
      img: client7,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Banner",
      designation: "Software Tester",
      img: client7,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Banner",
      designation: "Software Tester",
      img: client7,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Banner",
      designation: "Software Tester",
      img: client7,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Banner",
      designation: "Software Tester",
      img: client7,
    },
  ];

  const [startIndex, setStartIndex] = useState(0);
  const [slidesPerPage, setSlidesPerPage] = useState(4);

  const prevSlide = () => {
    setStartIndex(Math.max(startIndex - slidesPerPage, 0));
  };
  const nextSlide = () => {
    setStartIndex(
      Math.min(startIndex + slidesPerPage, myteam.length - slidesPerPage)
    );
  };

  const endIndex = Math.min(startIndex + slidesPerPage, myteam.length);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      console.log("Screen Width:", screenWidth);
      if (screenWidth < 768) {
        setSlidesPerPage(2);
      } else if (screenWidth < 450) {
        setSlidesPerPage(1);
      } else {
        // console.log("testing..................................");
        setSlidesPerPage(4);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        id="team"
        className="container"
        style={{ marginBottom: "40px", marginTop: "40px" }}
      >
        <div className="sliderWrapper ourTeamSlider">
          <div
            className="ourTeamSlide "
            style={{
              transform: `translateX(-${startIndex * (100 / slidesPerPage)}%)`,
              transition: "transform 0.5s ease",
              display: "flex",
              width: `${myteam.length * (100 / slidesPerPage)}%`,
              gap: "12px",
            }}
          >
            {myteam.map((review, index) => (
              <div
                className="ourteamCards "
                key={index}
                style={{
                  background: "#f8f8f8",
                  borderRadius: "20px",
                  padding: "20px",
                  flex: "0 0 24.5%",
                }}
              >
                <p className="clientmessage">{review.text}</p>
                <div className="clientreviewdetails">
                  <img
                    style={{ marginRight: "12px" }}
                    src={review.img}
                    alt={`clientImg-${index}`}
                  />
                  <div>
                    <h3>{review.name}</h3>
                    <p>{review.designation}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="ourteamTop"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="ourteamprevnextbtns">
          <button onClick={prevSlide} disabled={startIndex === 0}>
            <img
              src={next}
              alt="next"
              style={{ transform: "rotate(180deg)" }}
            />
          </button>
          <button onClick={nextSlide} disabled={endIndex === myteam.length}>
            <img src={next} alt="next" />
          </button>
        </div>
      </div>
    </>
  );
};

export default BottomReview;
