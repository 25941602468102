import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import headerLogo from "../Utils/Assets/headerLogo.png";
import closebar from "../Utils/Assets/close (1).png"
import headerLogoblue from "../Utils/Assets/footer/Final-LOGO 1.png"
import bar2 from "../Utils/Assets/bar2 - Copy.png"
import mobilebar from "../Utils/Assets/menu-01.png"

const Header = () => {
  const [mobileMenu, setMobilemenu] = useState(false);

  useEffect(() => {
    const anchorTags = document.querySelectorAll('a[href^="#"]');
    anchorTags.forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const href = this.getAttribute('href');
        const targetId = href.split('#')[1];
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const rect = targetElement.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const targetOffsetTop = rect.top + scrollTop;
          window.scrollTo({ top: targetOffsetTop, behavior: 'smooth' });
        }
      });
    });
  }, []);

  return (
    <div className="container headercontainer">
      <div className="header-box">
        <div>
          <img src={headerLogo} alt="header Logo" />
        </div>
        <nav className="header-nav">
          <ul>
            <li><Link className="links" to="/">Home</Link></li>
            <li><a href="#aboutus" className="links">About Us</a></li>
            <li><a href="#services" className="links" >Services</a></li>
            <li><Link className="links" to="/projects">Projects</Link></li>
            <li><a href="#team" className="links" >Team</a></li>
          </ul>
        </nav>
        <div className="contact-btn contact-nav">
          <button><a style={{ textDecoration: "none" }} href="#contactus">Contact Us</a></button>
        </div>
        {/* for small screen  */}
        <div className="mobileMenu">
          <button className="menu-btn justForcurson" onClick={() => setMobilemenu(true)}>
            <img src={mobilebar} alt="mobilebar" />
          </button>
          {
            mobileMenu &&
            <div className="mobile-menu">
              {/* close button */}
              <button className="close-btn menu-btn justForcurson" onClick={() => setMobilemenu(false)}>
                <img src={closebar} alt="closebar" />
              </button>
              <ul>
                <li><Link className="links" to="/">Home</Link></li>
                <li><a href="#aboutus" className="links">About Us</a></li>
                <li><a href="#services" className="links" >Services</a></li>
                <li><Link className="links" to="/projects">Projects</Link></li>
                <li><a href="#team" className="links" >Team</a></li>
              </ul>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Header;



// Header1
const Header1 = () => {
  const [mobileMenu, setMobilemenu] = useState(false);

  useEffect(() => {
    const anchorTags = document.querySelectorAll('a[href^="#"]');
    anchorTags.forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const href = this.getAttribute('href');
        const targetId = href.split('#')[1];
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const rect = targetElement.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const targetOffsetTop = rect.top + scrollTop;
          window.scrollTo({ top: targetOffsetTop, behavior: 'smooth' });
        }
      });
    });
  }, []);

  const navigate = useNavigate()
  const handleClick = (path) => {
    navigate("/");
    setTimeout(() => {
      // console.log("okay........")
      window.location.hash = `#${path}`;
    }, 0);
  };

  return (
    <div className="container">
      <div className="header-box">
        <div>
          <Link to="/" ><img src={headerLogoblue} alt="headerLogoblue" /></Link>
        </div>
        <nav className="header-nav1">
          <ul>
            <li><Link className="links" to="/">Home</Link></li>
            <li><a onClick={() => handleClick("aboutus")} href="#aboutus" className="links" >About Us</a></li>
            <li><a onClick={() => handleClick("services")} href="#services" className="links" >Services</a></li>
            <li><Link className="links" to="/projects">Projects</Link></li>
            <li><a onClick={() => handleClick("team")} href="#team" className="links" >Team</a></li>
          </ul>
        </nav>
        <div className="contact-btn1">
          <button><a style={{ textDecoration: "none", color: "white" }} href="#contactus">Contact Us</a></button>
        </div>
        {/* for small screen  */}
        <div className="mobileMenu">
          <button className="menu-btn justForcurson" onClick={() => setMobilemenu(true)}>
            <img src={bar2} alt="bar2" />
          </button>
          {
            mobileMenu &&
            <div className="mobile-menu">
              {/* close button */}
              <button className="close-btn menu-btn justForcurson" onClick={() => setMobilemenu(false)}>
                <img src={closebar} alt="closebar" />
              </button>
              <ul>
                <li><Link className="links" to="/">Home</Link></li>
                <li><a onClick={() => handleClick("aboutus")} href="#aboutus" className="links" >About Us</a></li>
                <li><a onClick={() => handleClick("services")} href="#services" className="links" >Services</a></li>
                <li><Link className="links" to="/projects">Projects</Link></li>
                <li><a onClick={() => handleClick("team")} href="#team" className="links" >Team</a></li>
              </ul>
            </div>
          }
        </div>
      </div>
    </div >
  );
};

export { Header1 };
