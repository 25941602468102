
import coinbase from "../Utils/Assets/our tech/Company logo.png"
import coinbasehover from "../Utils/Assets/our tech/coinbase.png"
import spotify from "../Utils/Assets/our tech/Vector.png"
import spotifyhover from "../Utils/Assets/our tech/spotify.png"
import slack from "../Utils/Assets/our tech/Company logo (1).png"
import slackhover from "../Utils/Assets/our tech/slack.png"
import Dropbox from "../Utils/Assets/our tech/Company logo (2).png"
import Dropboxhover from "../Utils/Assets/our tech/dropbox.png"
import webflow from "../Utils/Assets/our tech/Company logo (3).png"
import webflowhover from "../Utils/Assets/our tech/webflow.png"
import ZOOM from "../Utils/Assets/our tech/Company logo (4).png"
import ZOOMhover from "../Utils/Assets/our tech/zoom.png"
import attentive from "../Utils/Assets/our tech/Company logo (5).png"
import attentivehover from "../Utils/Assets/our tech/attentive.png"
import hotjar from "../Utils/Assets/our tech/Company logo (6).png"
import hotjarhover from "../Utils/Assets/our tech/hotjar.png"
import vision from "../Utils/Assets/our tech/Company logo (7).png"
import visionhover from "../Utils/Assets/our tech/invision.png"
import HELLOSIGN from "../Utils/Assets/our tech/Company logo (8).png"
import HELLOSIGNhover from "../Utils/Assets/our tech/hellosign.png"
import grammarly from "../Utils/Assets/our tech/Company logo (9).png"
import grammarlyhover from "../Utils/Assets/our tech/grammarly.png"
import { useState } from "react"

const OurPartner = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndex1, setHoveredIndex1] = useState(null);

  const ourTechPartners1 = [
    {
      normalimg: coinbase,
      alt: "coinbase",
      hoverimg: coinbasehover,
    },
    {
      normalimg: spotify,
      alt: "spotify",
      hoverimg: spotifyhover,
    },
    {
      normalimg: slack,
      alt: "slack",
      hoverimg: slackhover,
    },
    {
      normalimg: Dropbox,
      alt: "Dropbox",
      hoverimg: Dropboxhover,
    },
    {
      normalimg: webflow,
      alt: "webflow",
      hoverimg: webflowhover,
    },
    {
      normalimg: ZOOM,
      alt: "ZOOM",
      hoverimg: ZOOMhover,
    },
  ];
  const ourTechPartners2 = [
    {
      normalimg: attentive,
      alt: "coinbase",
      hoverimg: attentivehover,
    },
    {
      normalimg: hotjar,
      alt: "spotify",
      hoverimg: hotjarhover,
    },
    {
      normalimg: vision,
      alt: "slack",
      hoverimg: visionhover,
    },
    {
      normalimg: HELLOSIGN,
      alt: "Dropbox",
      hoverimg: HELLOSIGNhover,
    },
    {
      normalimg: grammarly,
      alt: "webflow",
      hoverimg: grammarlyhover,
    }
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const handleMouseEnter1 = (index) => {
    setHoveredIndex1(index);
  };

  const handleMouseLeave1 = () => {
    setHoveredIndex1(null);
  };

  return (
    <div className="container ourpartner">
      <div className='counter'>
        <div className='counters counter1'>
          <h2>8+</h2>
          <p>Years of Experience</p>
        </div>
        <div className='counters counter2'>
          <h2>200+</h2>
          <p>Projects Completed</p>
        </div>
        <div className='counters counter3'>
          <h2>100+</h2>
          <p>Satisfied Clients</p>
        </div>
        <div className='counters counter4'>
          <h2>20+</h2>
          <p>Expert Team Members</p>
        </div>
      </div>
      <h1>Our Tech Partners</h1>
      <div className="ourTechPartners1">
        {ourTechPartners1.map((partner, index) => (
          <div className="ourTechPartners" key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className="ourpartnerslogos"
              src={hoveredIndex === index ? partner.hoverimg : partner.normalimg}
              alt={partner.alt}
            />
          </div>
        ))}
      </div>
      {/* 2 */}
      <div className="ourTechPartners2">
        {ourTechPartners2.map((partner, index) => (
          <div className="ourTechPartners" key={index}
            onMouseEnter={() => handleMouseEnter1(index)}
            onMouseLeave={handleMouseLeave1}
          >
            <img
              className="ourpartnerslogos"
              src={hoveredIndex1 === index ? partner.hoverimg : partner.normalimg}
              alt={partner.alt}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurPartner;


