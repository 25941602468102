import React from 'react'
// import ourportfolio from "../Utils/Assets/headerLogo.png"
import ourwork1 from "../Utils/Assets/our work/Group 109.png"
import ourwork2 from "../Utils/Assets/our work/image 5.png"
import ourwork3 from "../Utils/Assets/our work/idea.png"
import ourwork4 from "../Utils/Assets/our work/image 8.png"
import ourwork5 from "../Utils/Assets/our work/image 9.png"
import ourwork2_1 from "../Utils/Assets/gallery/image 10.png"
import ourwork5_1 from "../Utils/Assets/gallery/image 14.png"
import { Link } from 'react-router-dom'
const OurWokr = () => {
    return (
        <div className='ourwork container'>
            <div className='ourworktop'>
                <div>
                    <h1>Our Work</h1>
                    <p>Discover our diverse portfolio showcasing innovation and client satisfaction.</p>
                </div>
                <div>
                    <button><a className='ourworkcontactus' href='#contactus'>Contact Us</a></button>
                </div>
            </div>
            <div className='ourworkcards'>
                {/* first two cards  */}
                <div className='ourworkfirstcards'>
                    <div className='ourwork1 ourworkcards'>
                        <img src={ourwork1} alt='ourwork1' />
                    </div>
                    <div className='ourwork2 ourworkcards'>
                        <img src={ourwork2} alt='ourwork2' />
                    </div>
                    <div className='ourwork2_1 ourworkcards'>
                        <img src={ourwork2_1} alt='ourwork2_1' />
                    </div>
                </div>
                <div className='ourworksecondcards'>
                    <div className='ourwork3 ourworkcards'>
                        <img src={ourwork3} alt='ourwork3' />
                        <h2>Get Inspired</h2>
                        <p>Explore our portfolio and ignite your imagination.</p>
                        <button><Link className='ourworkmoreprojects' to="/projects">View More Projects</Link></button>
                    </div>
                </div>
                <div className='ourworkthirdcards'>
                    <div className='ourwork4 ourworkcards'>
                        <img src={ourwork4} alt='ourwork4' />

                    </div>
                    <div className='ourwork5 ourworkcards'>
                        <img src={ourwork5} alt='ourwork5' />

                    </div>
                    <div className='ourwork5_1 ourworkcards'>
                        <img src={ourwork5_1} alt='ourwork5_1' />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default OurWokr