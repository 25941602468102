import React from "react";
import Facebook from "../Utils/Assets/contact/Facebook.png"
import LinkedIn from "../Utils/Assets/contact/LinkedIn.png"
import Twitter from "../Utils/Assets/contact/Twitter.png"
import YouTube from "../Utils/Assets/contact/YouTube.png"
import call from "../Utils/Assets/contact/call.png"
import email from "../Utils/Assets/contact/mail-01.png"
import location from "../Utils/Assets/contact/location-05.png"
const ContactUs = () => {
    return (
        <div id="contactus" className="container contactUs">
            <div className="contactInfo">
                <div className="contactInfoTop">
                    <h1>Contact Us</h1>
                    <p>
                        See what our clients have to say about their experience partnering
                        with Velox Tech Solutions
                    </p>
                </div>
                <div className="contactIcons">
                    <div className="contactRadius">
                        <img src={call} alt="call" />
                    </div>
                    <div className="contactDetail">
                        <h3>Let's Talk</h3>
                        <h2>+1 (123) 456-7890</h2>
                    </div>
                </div>
                <div className="contactIcons">
                    <div className="contactRadius">
                        <img src={email} alt="email" />
                    </div>
                    <div className="contactDetail">
                        <h3>Drop Us a Line</h3>
                        <h2>info@veloxtech.com</h2>
                    </div>
                </div>
                <div className="contactIcons">
                    <div className="contactRadius">
                        <img src={location} alt="location" />
                    </div>
                    <div className="contactDetail">
                        <h3>Visit Us</h3>
                        <h2>123 Main Street, City, Country</h2>
                    </div>
                </div>
                {/* stay socials  */}
                <div className="staysocials">
                    <h3>Stay Socials</h3>
                    <div className="staysocialicons">
                        <img src={Facebook} alt="Facebook" />
                        <img src={LinkedIn} alt="LinkedIn" />
                        <img src={Twitter} alt="Twitter" />
                        <img src={YouTube} alt="YouTube" />
                    </div>
                    {/* social icons here  */}
                </div>
            </div>
            <div className="contactForm">
                <div className="contactTopRow">
                    <h2>Get In Touch</h2>
                    <p>Let's connect and bring your ideas to life.</p>
                </div>
                <div className="contactFormDetails">
                    <form>
                        <div className="form-group">
                            <input placeholder="Your Name" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="form-group">
                            <input placeholder="Your Email" type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="form-group">
                            <input placeholder="Your Subject" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="form-group">
                            <textarea placeholder="How Can We Assist You?" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="contactUsbtn">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
