import React from "react";
import Header from "../Components/Header";
import Hero from "../Components/Hero";
import OurPartner from "../Components/OurPartner";
import AboutUs from "../Components/AboutUs";
import OurServices from "../Components/OurServices";
import OurWokr from "../Components/OurWokr";
import ContactUs from "../Components/ContactUs";
import Footer from "../Components/Footer";
import OurTeam from "../Components/OurTeam";
import ClientReview, { ClientReviewSmallScreen } from "../Components/ClientReview";

const HomePage = () => {
  return (
    <div>
      <div className="hero-container">
        <Header />
        <Hero />
      </div>
      <OurPartner />
      <AboutUs />
      <OurServices />
      <OurWokr />
      <OurTeam />
      <div className="clientreviewlarge">
        <ClientReview />
      </div>
      <div className="clientreviewsmall">
        <ClientReviewSmallScreen />
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HomePage;
