import React, { useEffect, useState } from "react";
import galleryPic1 from "../Utils/Assets/gallery/image 10.png";
import galleryPic2 from "../Utils/Assets/gallery/image 11.png";
import galleryPic3 from "../Utils/Assets/gallery/image 12.png";
import galleryPic4 from "../Utils/Assets/gallery/image 13.png";
import galleryPic5 from "../Utils/Assets/gallery/image 14.png";
import galleryPic6 from "../Utils/Assets/gallery/image 15.png";
import galleryPic7 from "../Utils/Assets/gallery/image 16.png";
import galleryPic8 from "../Utils/Assets/gallery/image 17.png";
import galleryPic9 from "../Utils/Assets/gallery/image 18.png";

import ProjectMobile from "./ProjectMobil";
import { Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap
import modeltopbar from "../Utils/Assets/model/modeltopbar.png";
import modelmain from "../Utils/Assets/model/modelmain.png";
const Gallery = ({ toggleBackgroundColor }) => {
  const [selectedButton, setSelectedButton] = useState("All");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isSlider, setIsSlider] = useState(false);
  const galleryItems = [
    {
      img: galleryPic1,
      bg: "#0C1930",
      type: ["webdevelopment"],
    },
    {
      img: galleryPic2,
      bg: "#E0DFE5",
      type: ["webdevelopment"],
    },
    {
      img: galleryPic3,
      bg: "#C1C1C1",
      type: ["seo/aso"],
    },
    {
      img: galleryPic4,
      bg: "#84A4A2",
      type: ["ui/uxdesign"],
    },
    {
      img: galleryPic5,
      bg: "#C3BEDC",
      type: ["graphicdesign"],
    },
    {
      img: galleryPic6,
      bg: "#0C1930",
      type: ["appdevelopment"],
    },
    {
      img: galleryPic7,
      bg: "#2E3946",
      type: ["webdevelopment", "appdevelopment"],
    },
    {
      img: galleryPic8,
      bg: "#233229",
      type: ["appdevelopment"],
    },
    {
      img: galleryPic9,
      bg: "#000000",
      type: ["appdevelopment"],
    },
  ];
  const allbuttonsForsmallscreen = [
    { label: "All", onClick: () => setSelectedButton("All") },
    {
      label: "Web Development",
      onClick: () => setSelectedButton("Web Development"),
    },
    {
      label: "App Development",
      onClick: () => setSelectedButton("App Development"),
    },
    { label: "UI/UX Design", onClick: () => setSelectedButton("UI/UX Design") },
    {
      label: "Graphic Design",
      onClick: () => setSelectedButton("Graphic Design"),
    },
    { label: "SEO/ASO", onClick: () => setSelectedButton("SEO/ASO") },
    {
      label: "DevOps & CloudOps",
      onClick: () => setSelectedButton("DevOps & CloudOps"),
    },
    {
      label: "Virtual Assistance",
      onClick: () => setSelectedButton("Virtual Assistance"),
    },
    {
      label: "Social Media Handling",
      onClick: () => setSelectedButton("Social Media Handling"),
    },
    {
      label: "Advertisement",
      onClick: () => setSelectedButton("Advertisement"),
    },
    {
      label: "AI Integration",
      onClick: () => setSelectedButton("AI Integration"),
    },
    { label: "Unit Testing", onClick: () => setSelectedButton("Unit Testing") },
    { label: "Consulting", onClick: () => setSelectedButton("Consulting") },
  ];

  const filterGalleryItems = () => {
    let filteredItems = [];
    if (selectedButton === "All") {
      filteredItems = galleryItems;
    } else {
      filteredItems = galleryItems.filter((item) =>
        item.type.includes(selectedButton.replace(/\s/g, "").toLowerCase())
      );
    }
    return filteredItems;
  };

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSlider(window.innerWidth < 1000);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);
  var visibleButtons;
  if (window.innerWidth < 640) {
    visibleButtons = 1;
  } else {
    visibleButtons = 2;
  }

  useEffect(() => {
    if (currentIndex < 0) {
      setCurrentIndex(0);
    } else if (currentIndex >= allbuttonsForsmallscreen.length) {
      setCurrentIndex(allbuttonsForsmallscreen.length - visibleButtons);
    }
  }, [currentIndex, visibleButtons, allbuttonsForsmallscreen.length]);
  // ProjectViewModel
  const [model, setShowModel] = useState(false);
  const handleShowmodel = () => {
    setShowModel(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="gallerymodel">
      {/* gallery */}
      <div className="container gallery">
        {/* show model  */}

        {isSlider ? (
          <div className="slider-container">
            <ProjectMobile
              allbuttonsForsmallscreen={allbuttonsForsmallscreen}
              setSelectedButton={setSelectedButton}
            />
          </div>
        ) : (
          <div className="gallerybuttons">
            <div className="gallerytopbtns">
              <button onClick={() => setSelectedButton("All")}>All</button>
              <button onClick={() => setSelectedButton("Web Development")}>
                Web Development
              </button>
              <button onClick={() => setSelectedButton("App Development")}>
                App Development
              </button>
              <button onClick={() => setSelectedButton("UI/UX Design")}>
                UI/UX Design
              </button>
              <button onClick={() => setSelectedButton("Graphic Design")}>
                Graphic Design
              </button>
              <button onClick={() => setSelectedButton("SEO/ASO")}>
                SEO/ASO
              </button>
            </div>
            <div className="gallerymiddlebtns">
              <button onClick={() => setSelectedButton("DevOps & CloudOps")}>
                DevOps & CloudOps
              </button>
              <button onClick={() => setSelectedButton("Virtual Assistance")}>
                Virtual Assistance
              </button>
              <button
                onClick={() => setSelectedButton("Social Media Handling")}
              >
                Social Media Handling
              </button>
              <button onClick={() => setSelectedButton("Advertisement")}>
                Advertisement
              </button>
              <button onClick={() => setSelectedButton("AI Integration")}>
                AI Integration
              </button>
            </div>
            <div className="gallerybottombtns">
              <button onClick={() => setSelectedButton("Unit Testing")}>
                Unit Testing
              </button>
              <button onClick={() => setSelectedButton("Consulting")}>
                Consulting
              </button>
            </div>
          </div>
        )}
        {/* show gallery items  */}
        <div className="galleryContainer">
          <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header
              closeButton
              style={{ borderBottom: "0", padding: "20px" }}
            ></Modal.Header>

            <Modal.Body>
              <div className="modelinner">
                {/* model top bar */}
                <div className="modelTopBar">
                  <div className="modelTopBarleft">
                    <img src={modeltopbar} alt="model" />
                  </div>
                  <div className="modelTopBarright">
                    <h1>Mobile E-Commerce App</h1>
                    <p>
                      Revolutionize the way you shop with our intuitive mobile
                      e-commerce app. Seamlessly browse products, make
                      purchases, and track orders, all from the convenience of
                      your smartphone.
                    </p>
                    <div className="modelTopBarrightbutton">
                      <Button onClick={handleShow}>Launch Demo Modal</Button>
                    </div>
                    <div className="modelTopBarrightlink">
                      <p>
                        Links: <a href="#">App Demo</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* model bottom */}
                <div className="modelMiddlebar1">
                  {/* modelMiddlebar1 */}
                  <img src={modelmain} alt="model" />
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {filterGalleryItems().map((item, index) => (
            <div
              key={index}
              style={{ backgroundColor: item.bg, position: "relative" }}
              className="gallery-item"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={handleShow}
            >
              <img src={item.img} alt="Gallery Item" />
              {hoveredItem === index && (
                <div
                  className="overlay"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "16px",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: 0,
                      fontSize: "20px",
                      border: "2px solid #f8f8f8",
                      padding: "10px 40px",
                      borderRadius: "5px",
                    }}
                  >
                    Visit
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        {/* load more */}
        <div className="loadmore">
          <button className="loadmorebtn">Load More</button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
