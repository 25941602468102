import React, { useState, useEffect } from "react";
import ourteamPic1 from "../Utils/Assets/slide/Rectangle 2342.png";
import ourteamPic2 from "../Utils/Assets/slide/Rectangle 2343.png";
import ourteamPic3 from "../Utils/Assets/slide/Rectangle 2344.png";
import ourteamPic4 from "../Utils/Assets/slide/Rectangle 2345.png";
import prev from "../Utils/Assets/slide/Group 39475.png";
import next from "../Utils/Assets/slide/Group 39476.png";

const OurTeam = () => {
  const myteam = [
    { img: ourteamPic1, name: "Hassan Adeel" },
    { img: ourteamPic2, name: "Umer Farooq" },
    { img: ourteamPic3, name: "Sana Naz" },
    { img: ourteamPic4, name: "Ali Haidar" },
    { img: ourteamPic1, name: "Hassan Adeel" },
    { img: ourteamPic2, name: "Umer Farooq" },
    { img: ourteamPic3, name: "Sana Naz" },
    { img: ourteamPic4, name: "Ali Haidar" },
  ];

  const [startIndex, setStartIndex] = useState(0);
  const [slidesPerPage, setSlidesPerPage] = useState(4);

  const prevSlide = () => {
    setStartIndex(Math.max(startIndex - slidesPerPage, 0));
  };
  const nextSlide = () => {
    setStartIndex(
      Math.min(startIndex + slidesPerPage, myteam.length - slidesPerPage)
    );
  };

  const endIndex = Math.min(startIndex + slidesPerPage, myteam.length);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      console.log("Screen Width:", screenWidth);
      if (screenWidth < 768) {
        setSlidesPerPage(2);
      } else if (screenWidth < 450) {
        setSlidesPerPage(1);
      } else {
        // console.log("testing..................................");
        setSlidesPerPage(4);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="team" className="container ourteam">
      <div className="ourteamTop">
        <div>
          <h1>Meet Our Team</h1>
          <p>Discover the faces behind Velox Tech Solutions' success.</p>
        </div>
        <div className="ourteamprevnextbtns">
          <button onClick={prevSlide} disabled={startIndex === 0}>
            <img
              src={next}
              alt="next"
              style={{ transform: "rotate(180deg)" }}
            />
          </button>
          <button onClick={nextSlide} disabled={endIndex === myteam.length}>
            <img src={next} alt="next" />
          </button>
        </div>
      </div>
      <div className="sliderWrapper ourTeamSlider">
        <div
          className="ourTeamSlide"
          style={{
            transform: `translateX(-${startIndex * (100 / slidesPerPage)}%)`,
            transition: "transform 0.5s ease",
            display: "flex",
            width: `${myteam.length * (100 / slidesPerPage)}%`,
          }}
        >
          {myteam.map((item, index) => (
            <div className="ourteamCards" key={index}>
              <img
                className="ourteampic"
                src={item.img}
                alt={`ourteamPic-${index}`}
              />
              <h2 className="ourteamname">{item.name}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
