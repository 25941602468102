import React from 'react'
import target from "../Utils/Assets/about us/target 1.png"
import strategy from "../Utils/Assets/about us/strategy 1.png"
import communication from "../Utils/Assets/about us/communication_call ringing 1 (1).png"
import Rectangle1 from "../Utils/Assets/about us/Rectangle 2302.png"
import Rectangle2 from "../Utils/Assets/about us/Rectangle 2303.png"
const AboutUs = () => {
    return (
        <div id='aboutus' className='aboutUs container'>
            <h1>About Us</h1>
            <p>Velox Tech Solution: Your innovation partner.
                <br />
                We craft custom software solutions for your success.</p>
            {/* about us cards  */}
            <div className='aboutuscardsimgs'>
                {/* aboutUsCards */}
                <div className='aboutUsCards'>
                    <div className='card1 cards'>
                        <img src={target} alt='target' />
                        <h2>Our Mission</h2>
                        <p>Empower businesses with
                            cutting-edge tech.</p>
                    </div>
                    <div className='card2 cards'>
                        <img src={strategy} alt='strategy' />
                        <h2>Our Approach</h2>
                        <p>Collaborative. Transparent.
                            Innovative.</p>
                    </div>
                    <div className='card3 cards'>
                        <img src={communication} alt='communication' />
                        <h2>Get in Touch</h2>
                        <p>Ready to take your business
                            to the next level?</p>
                        <button><a className='aboutuscontact' href='#contactus'>Contact Us</a></button>
                    </div>
                </div>
                {/* stylish lines  */}
                <div className='stylishBorderAbout'>
                    <img src={Rectangle1} className='Rectangle1 Rectangle' alt='Rectangle1' />
                    <img src={Rectangle2} className='Rectangle2 Rectangle' alt='Rectangle2' />
                </div>
            </div>
        </div>
    )
}

export default AboutUs