import React from "react";
import Gallery from "../Components/Gallery";
import ContactUs from "../Components/ContactUs";
import Footer from "../Components/Footer";
import { Header1 } from "../Components/Header";
// import ProjectViewModel from '../Components/ProjectViewModel'

const Project = ({ toggleBackgroundColor }) => {
  return (
    <div>
      {/* <ProjectViewModel/> */}
      <Header1 />
      <Gallery />
      <ContactUs toggleBackgroundColor={toggleBackgroundColor} />
      <Footer />
    </div>
  );
};

export default Project;
