import React from "react";
import { useState, useEffect, useRef } from "react";
import reviewStyle from "../Utils/Assets/slide/“.png";
import client1 from "../Utils/Assets/slide/Ellipse 57.png";
import client2 from "../Utils/Assets/slide/Ellipse 57 (1).png";
import client3 from "../Utils/Assets/slide/Ellipse 57 (2).png";
import client4 from "../Utils/Assets/slide/Ellipse 57 (3).png";
import client5 from "../Utils/Assets/slide/Ellipse 57 (4).png";
import client6 from "../Utils/Assets/slide/Ellipse 57 (5).png";
import client7 from "../Utils/Assets/slide/Ellipse 57 (6).png";
import prev from "../Utils/Assets/slide/Group 39475.png";
import next from "../Utils/Assets/slide/Group 39476.png";
import BottomReview from "./BottomReview";
// for large screen
const ClientReview = () => {
  const topReviews = [
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "John Doe",
      designation: "Software Developer",
      img: client1,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Jane Smith",
      designation: "Project Manager",
      img: client2,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Wayne  ",
      designation: "UI/UX Designer",
      img: client3,
    },
  ];

  return (
    <>
      <div className="container clientReview">
        <div className="clientreviewtop">
          <h1>Client Reviews</h1>
          <p>
            See what our clients have to say about their experience partnering
            with Velox Tech Solutions
          </p>
        </div>
        {/* client reviews top cards */}
        <div className="clientreviewscards" style={{ gap: "12px" }}>
          {topReviews.map((review, index) => (
            <div
              className="clientreviewcardstop"
              key={index}
              style={{
                background: "#f8f8f8",
                borderRadius: "20px",
                padding: "20px",
              }}
            >
              <img src={reviewStyle} alt="reviewStyle" />
              <p className="clientmessage">{review.text}</p>
              <div className="clientreviewdetails">
                <img
                  style={{ marginRight: "12px" }}
                  src={review.img}
                  alt={`clientImg-${index}`}
                />
                <div>
                  <h3>{review.name}</h3>
                  <p>{review.designation}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BottomReview />
    </>
  );
};

export default ClientReview;

// for small screen
const ClientReviewSmallScreen = () => {
  const reviews = [
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "John Doe",
      designation: "Software Developer",
      img: client1,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Jane Smith",
      designation: "Project Manager",
      img: client2,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Wayne  ",
      designation: "UI/UX Designer",
      img: client3,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "John Doe",
      designation: "Software Tester",
      img: client4,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Clark Kent",
      designation: "Ethical Hacker",
      img: client5,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "John Doe",
      designation: "Scrum Master",
      img: client6,
    },
    {
      text: "Working with Velox Technologies was a game-changer for our business. Their expertise and professionalism are unmatched.",
      name: "Bruce Banner",
      designation: "Software Tester",
      img: client7,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const [slideWidth, setSlideWidth] = useState(0);

  useEffect(() => {
    const resizeListener = () => {
      if (sliderRef.current) {
        setSlideWidth(sliderRef.current.clientWidth);
      }
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  useEffect(() => {
    sliderRef.current.scrollTo(currentIndex * slideWidth, 0);
  }, [currentIndex, slideWidth]);

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const handlePrevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length
    );
  };

  return (
    <div
      className="container clientReviewslide"
      style={{ overflowX: "hidden" }}
    >
      <div className="clientreviewtop">
        <h1>Client Reviews</h1>
        <p>
          See what our clients have to say about their experience partnering
          with Velox Tech Solutions
        </p>
      </div>
      <div
        className="clientreviewscardsslide"
        ref={sliderRef}
        style={{
          overflowX: "hidden",
          whiteSpace: "nowrap",
          position: "relative",
        }}
      >
        {reviews.map((review, index) => (
          <div
            key={index}
            className="clientreviewcard"
            style={{
              display: "inline-block",
              width: `${slideWidth}px`,
              maxWidth: "100%",
              textAlign: "center",
              verticalAlign: "top",
              transition: "transform 0.5s ease",
              transform: `translateX(-${currentIndex * slideWidth}px)`,
            }}
          >
            <img
              className="reviewStyleimg"
              src={reviewStyle}
              alt="reviewStyle"
            />
            <p className="clientmessage">{review.text}</p>
            <div className="clientreviewdetails">
              <img
                style={{ marginRight: "12px" }}
                src={review.img}
                alt={`clientImg-${index}`}
              />
              <div>
                <h3>{review.name}</h3>
                <p>{review.designation}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="clientreviewsbuttons" style={{ textAlign: "center" }}>
        <button onClick={handlePrevSlide} disabled={currentIndex === 0}>
          <img src={prev} alt="prev" />
        </button>
        <button onClick={handleNextSlide} disabled={currentIndex === 3}>
          <img src={next} alt="prev" />
        </button>
      </div>
    </div>
  );
};

export { ClientReviewSmallScreen };
