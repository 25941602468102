import React, { useState, useEffect } from "react";
import ourteamPic1 from "../Utils/Assets/slide/Rectangle 2342.png";
import ourteamPic2 from "../Utils/Assets/slide/Rectangle 2343.png";
import ourteamPic3 from "../Utils/Assets/slide/Rectangle 2344.png";
import ourteamPic4 from "../Utils/Assets/slide/Rectangle 2345.png";
import prev from "../Utils/Assets/slide/Group 39475.png";
import next from "../Utils/Assets/slide/Group 39476.png";

const ProjectMobile = ({ allbuttonsForsmallscreen, setSelectedButton }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [slidesPerPage, setSlidesPerPage] = useState(4);

  const prevSlide = () => {
    setStartIndex(Math.max(startIndex - slidesPerPage, 0));
  };
  const nextSlide = () => {
    setStartIndex(
      Math.min(
        startIndex + slidesPerPage,
        allbuttonsForsmallscreen.length - slidesPerPage
      )
    );
  };

  const endIndex = Math.min(
    startIndex + slidesPerPage,
    allbuttonsForsmallscreen.length
  );

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      console.log("Screen Width:", screenWidth);
      if (screenWidth < 768) {
        setSlidesPerPage(2);
      } else if (screenWidth < 450) {
        setSlidesPerPage(1);
      } else {
        // console.log("testing..................................");
        setSlidesPerPage(4);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="team" className="container ourteam">
      <div className="sliderWrapper ourTeamSlider">
        <div
          className="ourTeamSlide"
          style={{
            transform: `translateX(-${startIndex * (100 / slidesPerPage)}%)`,
            transition: "transform 0.5s ease",
            display: "flex",
            width: `${
              allbuttonsForsmallscreen.length * (100 / slidesPerPage)
            }%`,
          }}
        >
          {allbuttonsForsmallscreen.map((item, index) => (
            <div className="" key={index}>
              <button onClick={() => setSelectedButton(item.label)}>
                {item.label}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectMobile;
