import React from "react";
import vr1 from "../Utils/Assets/VR1.png";
import idea from "../Utils/Assets/Idea 3D Icon PNG File2.png";
import busket from "../Utils/Assets/21.png";
import hero1img from "../Utils/Assets/hero groups/Group 42.png";
import hero2img from "../Utils/Assets/hero groups/Group 43.png";
import hero3img from "../Utils/Assets/hero groups/Group 44.png";
import hero4img from "../Utils/Assets/hero groups/Group 45.png";
import communication from "../Utils/Assets/communication_speaker1.png";
import alert from "../Utils/Assets/341.png";
const Hero = () => {
  return (
    <div className="container hero-section">
      <h1 className="hero-heading">
        <img className="herotopimg1" src={vr1} alt="vr1" />
        Transforming Ideas{" "}
        <img className="transformingidea" src={idea} alt="idea" />
        into <img
          className="herotopimg2"
          src={busket}
          alt="busket"
        /> <br /> Reality with Velox Tech Solution
      </h1>
      <p className="hero-para">
        Discover the power of innovation and expertise with Velox Tech
        Solutions. We specialize in crafting bespoke solutions tailored to your
        needs, from mobile apps to UI/UX design and beyond. Let's bring your
        vision to life, together.
      </p>
      {/* button with pics  */}
      <div className="herobtnpics">
        <div className="hero-btn contact-btn">
          <button>
            <a style={{ textDecoration: "none" }} href="#contactus">
              Get Started
            </a>
          </button>
        </div>
        <div className="hero-alert">
          <img className="heroimg1" src={communication} alt="communication" />
          <img className="heroimg2" src={alert} alt="alert" />
        </div>
      </div>
      <div className="hero_groups">
          <img src={hero4img} alt="hero4img" />
          <img src={hero3img} alt="hero3img" />
          <img src={hero2img} alt="hero2img" />
          <img src={hero1img} alt="hero1img" />
      </div>
    </div>
  );
};

export default Hero;
