import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useMediaQuery } from "react-responsive";
import mobileapp from "../Utils/Assets/services/Rectangle 2333.png";

const OurServices = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define your mobile breakpoint

  const data = [
    "Mob App Development",
    "Website Development",
    "UI/UX Design",
    "Graphic Design",
    "DevOps & CloudOps",
    "Virtual Assistance",
    "Social Media Handling",
    "SEO/ASO",
    "Advertisement",
    "AI Integration",
    "Unit Testing",
    "Consulting",
  ];

  const [selectedService, setSelectedService] = useState(data[0]);
  const [activeButton, setActiveButton] = useState(0);

  const handleServiceClick = (service, index) => {
    setSelectedService(service);
    setActiveButton(index);
  };

  return (
    <div id="services" className="ourservices container">
      <h1>Our Services</h1>
      <p>Empowering your digital journey with tailored solutions.</p>

      {/* Accordion for mobile view */}
      {isMobile ? (
        <div className="accordion-mobile">
          <Accordion defaultActiveKey="0">
            {data.map((service, index) => (
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header
                  onClick={() => handleServiceClick(service, index)}
                >
                  {service}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="ourServicescard" style={{ display: "flex" }}>
                    <div className="service2 ">
                      <h2>{selectedService}</h2>
                      <p>
                        Crafting intuitive mobile applications that resonate
                        with your audience.
                      </p>
                      <img
                        className="service2img"
                        src={mobileapp}
                        alt="mobileapp"
                      />
                      <button
                        style={{
                          backgroundColor:
                            activeButton === 6 ? "#1b1464" : "transparent",
                          color: activeButton === 6 ? "white" : "black",
                          cursor: "pointer",
                        }}
                      >
                        <a
                          className="servicescontactus"
                          href="#contactus"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Contact Us
                        </a>
                      </button>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      ) : (
        <div className="ourServicescard" style={{ display: "flex" }}>
          <div className="service1 ourservicescards">
            {data.slice(0, 6).map((service, index) => (
              <h2
                key={index}
                style={{
                  backgroundColor:
                    activeButton === index ? "#1b1464" : "transparent",
                  color: activeButton === index ? "white" : "black",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
                onClick={() => handleServiceClick(service, index)}
              >
                {service}
              </h2>
            ))}
          </div>
          <div className="service2 ourservicescards">
            <h2>{selectedService}</h2>
            <p>
              Crafting intuitive mobile applications that resonate with your
              audience.
            </p>
            <img className="service2img" src={mobileapp} alt="mobileapp" />
            <button
              style={{
                backgroundColor: activeButton === 6 ? "#1b1464" : "transparent",
                color: activeButton === 6 ? "white" : "black",
                cursor: "pointer",
              }}
            >
              <a
                className="servicescontactus"
                href="#contactus"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Contact Us
              </a>
            </button>
          </div>
          <div className="service3 ourservicescards">
            {data.slice(6).map((service, index) => (
              <h2
                key={index}
                style={{
                  backgroundColor:
                    activeButton === index + 6 ? "#1b1464" : "transparent",
                  color: activeButton === index + 6 ? "white" : "black",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
                onClick={() => handleServiceClick(service, index + 6)}
              >
                {service}
              </h2>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OurServices;
