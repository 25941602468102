import React from 'react'
import velox from "../Utils/Assets/footer/Final-LOGO 1.png"
const Footer = () => {
    return (
        <div className='container footer'>
            <div className='footerVelox'>
                <img src={velox} alt="velox" />
                <p>Velox Tech Solutions is your premier partner for innovative software solutions. We specialize in crafting bespoke applications tailored to your needs, from mobile apps to website development and more.</p>
            </div>
            <button disabled>© 2024 Velox Tech Solution. All rights reserved.</button>
        </div>
    )
}

export default Footer