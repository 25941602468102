import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./Pages/index";
import Project from "./Pages/Project";
import Gallery from "./Components/Gallery";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const [isBackgroundDark, setIsBackgroundDark] = useState(false);
  const toggleBackgroundColor = () => {
    setIsBackgroundDark(!isBackgroundDark);
  };

  return (
    <>
      <div className={isBackgroundDark ? "dark-background" : ""}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/projects" element={<Project />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
